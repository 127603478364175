import {RefdataActions, RefdataActionTypes, RefdataType} from './refdata.actions';
import {IFeedbackModel} from '../../core/models/i-feedback.model';
import {IRefdataModel} from '../models/i-refdata.model';
import {ICountryRefdataModel} from '../models/i-country-refdata.model';
import {IExchangeRateRefdataModel} from '../models/i-exchange-rate-refdata.model';
import { IMaterialRefdataModel } from '../models/i-material-refdata.model';
import { IMaterialGroupRefdataModel } from '../models/i-material-group-refdata.model';
import { IChannelRefdataModel } from '../models/i-channel-refdata.model';

export interface RefdataState {
  feedbacks: IFeedbackModel[];
  selectedId: number;
  regions: IRefdataModel[];
  locations: IRefdataModel[];
  usedEquipments: IRefdataModel[];
  indirectPurchasingCategories: IRefdataModel[];
  // categoryCodeDescriptions: IRefdataModel[];
  // countries: IRefdataModel[];
  fhaRates: IRefdataModel[];
  currencies: IRefdataModel[];
  paymentTerms: IRefdataModel[];
  certifications: IRefdataModel[];

  categoryType: IRefdataModel[];
  category: IRefdataModel[];
  subCategory: IRefdataModel[];
  plants: IRefdataModel[];
  storageLocations: IRefdataModel[];
  itemCategory: IRefdataModel[];
  unitMeasures: IRefdataModel[];
  costCenters: IRefdataModel[];
  assetNumbers: IRefdataModel[];
  assetSubNumbers: IRefdataModel[];
  orderNumbers: IRefdataModel[];
  ledgerAccounts: IRefdataModel[];
  orgs: IRefdataModel[];
  departments: IRefdataModel[];
  
  countries: ICountryRefdataModel[];
  exchangeRates: IExchangeRateRefdataModel[];
  materials: IMaterialRefdataModel[];
  materialGroups: IMaterialGroupRefdataModel[];

  channels: IChannelRefdataModel[];
}

const initialState: RefdataState = {
  feedbacks: [],
  selectedId: null,
  // categoryCodeDescriptions: [],
  // countries: [],
  currencies: [],
  fhaRates: [],
  indirectPurchasingCategories: [],
  locations: [],
  paymentTerms: [],
  regions: [],
  usedEquipments: [],
  certifications: [],

  categoryType: [],
  category: [],
  subCategory: [],
  plants: [],
  storageLocations: [],
  itemCategory: [],
  unitMeasures: [],
  costCenters: [],
  assetNumbers: [],
  assetSubNumbers: [],
  orderNumbers: [],
  ledgerAccounts: [],
  orgs: [],
  departments: [],

  countries: [],
  exchangeRates: [],
  materials: [],
  materialGroups: [],

  channels: []
};

export function refdataReducer(state = initialState, action: RefdataActions): RefdataState {

  switch (action.type) {
    case RefdataActionTypes.StartRefdata:
      return {
        ...state
      };
    case RefdataActionTypes.GetRefdataSuccess:
      switch (action.payload.refdataTypeId) {
        // case RefdataType.categoryCodeDescription:
        //   return {
        //     ...state,
        //     categoryCodeDescriptions: [...state.categoryCodeDescriptions, ...action.payload.refdatas]
        //   };
        // case RefdataType.country:
        //   return {
        //     ...state,
        //     countries: [...state.countries, ...action.payload.refdatas]
        //   };
        // case RefdataType.currency:
        //   return {
        //     ...state,
        //     currencies: [...state.currencies, ...action.payload.refdatas]
        //   };
        case RefdataType.fhaRate:
          return {
            ...state,
            fhaRates: [...action.payload.refdatas]
          };
        case RefdataType.indirectPurchasingCategory:
          return {
            ...state,
            indirectPurchasingCategories: [...action.payload.refdatas]
          };
        case RefdataType.location:
          return {
            ...state,
            locations: [...action.payload.refdatas]
          };
        case RefdataType.paymentTerms:
          return {
            ...state,
            paymentTerms: [...action.payload.refdatas]
          };
        case RefdataType.region:
          return {
            ...state,
            regions: [...action.payload.refdatas]
          };
        case RefdataType.usedEquipment:
          return {
            ...state,
            usedEquipments: [...action.payload.refdatas]
          };
        case RefdataType.certification:
          return {
            ...state,
            certifications: [...action.payload.refdatas]
          };
        case RefdataType.categoryType:
          return {
            ...state,
            categoryType: [...action.payload.refdatas]
          };
        case RefdataType.category:
          return {
            ...state,
            category: [...action.payload.refdatas]
          };
        case RefdataType.subCategory:
          return {
            ...state,
            subCategory: [...action.payload.refdatas]
          };
        case RefdataType.plant:
          return {
            ...state,
            plants: [...action.payload.refdatas]
          };
        case RefdataType.storageLocation:
          return {
            ...state,
            storageLocations: [...action.payload.refdatas]
          };
        case RefdataType.itemCategory:
          return {
            ...state,
            itemCategory: [...action.payload.refdatas]
          };
        case RefdataType.unitMeasure:
          return {
            ...state,
            unitMeasures: [...action.payload.refdatas]
          }
        case RefdataType.costCenter:
          return {
            ...state,
            costCenters: [...action.payload.refdatas]
          } 
        case RefdataType.assetNumber:
          return {
            ...state,
            assetNumbers: [...action.payload.refdatas]
          }
        case RefdataType.assetSubNumber:
          return {
            ...state,
            assetSubNumbers: [...action.payload.refdatas]
          }
        case RefdataType.orderNumber:
          return {
            ...state,
            orderNumbers: [...action.payload.refdatas]
          }
        case RefdataType.ledgerAccount:
          return {
            ...state,
            ledgerAccounts: [...action.payload.refdatas]
          }
        case RefdataType.orgs:
          return {
            ...state,
            orgs: [...action.payload.refdatas]
          }
        case RefdataType.departments:
          return {
            ...state,
            departments: [...action.payload.refdatas]
          }
      }
      return {
        ...state
      };
    case RefdataActionTypes.GetRefdataError:
      return {
        ...state
      };
    case RefdataActionTypes.GetCountriesSuccess:
      return {
        ...state,
        countries: action.payload
      };
    case RefdataActionTypes.GetCountriesError:
      return {
        ...state,
        feedbacks: action.payload
      };
    case RefdataActionTypes.GetExchangeRatesSuccess:
      return {
        ...state,
        exchangeRates: action.payload
      };
    case RefdataActionTypes.GetExchangeRatesError:
      return {
        ...state,
        feedbacks: action.payload
      };
    case RefdataActionTypes.GetMaterialsSuccess:
      return {
        ...state,
        materials: action.payload
      };
    case RefdataActionTypes.GetMaterialsError:
      return {
        ...state,
        feedbacks: action.payload
      };
    case RefdataActionTypes.GetMaterialGroupsSuccess:
      return {
        ...state,
        materialGroups: action.payload
      };
    case RefdataActionTypes.GetMaterialGroupsError:
      return {
        ...state,
        feedbacks: action.payload
      };
    case RefdataActionTypes.GetChannelsSuccess:
      return {
        ...state,
        channels: action.payload
      };
    case RefdataActionTypes.GetChannelsError:
      return {
        ...state,
        feedbacks: action.payload
      };
    default:
      return state;
  }
}
