import {Action} from '@ngrx/store';
import {IFeedbackModel} from '../../core/models/i-feedback.model';
import {IRefdataGetResponseModel} from '../models/store/i-refdata.get.response.model';
import {ICountryRefdataModel} from '../models/i-country-refdata.model';
import {IExchangeRateRefdataModel} from '../models/i-exchange-rate-refdata.model';
import { IMaterialRefdataModel } from '../models/i-material-refdata.model';
import { IMaterialGroupRefdataModel } from '../models/i-material-group-refdata.model';
import { IChannelRefdataModel } from '../models/i-channel-refdata.model';

export enum RefdataType {
  region = 1,
  location = 2,
  categoryType = 3,
  category = 4,
  subCategory = 5,
  fhaRate = 6,
  paymentTerms = 7,
  certification = 8,
  usedEquipment = 9,
  mailingList = 10,
  indirectPurchasingCategory = 11,
  plant = 12,
  storageLocation = 13,
  itemCategory = 14,
  unitMeasure = 15,
  costCenter = 16,
  assetNumber = 17,
  assetSubNumber = 18,
  orderNumber = 19,
  ledgerAccount = 20,
  orgs = 21,
  departments = 22
}

export enum RefdataActionTypes {
  StartRefdata = '[Refdata] Start',
  ShowGlobalFeedback = '[Refdata] Global Feedback',
  InitializeRefdata = '[Refdata] Initialize Refdata',
  GetRefdata = '[Refdata] Get Refdata',
  GetRefdataSuccess = '[Refdata] Get Refdatas Success',
  GetRefdataError = '[Refdata] Get Refdatas Error',
  GetCountries = '[Refdata] GetCountries',
  GetCountriesSuccess = '[Refdata] GetCountriesSuccess Success',
  GetCountriesError = '[Refdata] GetCountriesSuccess Error',
  GetExchangeRates = '[Refdata] GetExchangeRates',
  GetExchangeRatesSuccess = '[Refdata] GetExchangeRates Success',
  GetExchangeRatesError = '[Refdata] GetExchangeRates Error',
  GetMaterials = '[Refdata] GetMaterials',
  GetMaterialsSuccess = '[Refdata] GetMaterials Success',
  GetMaterialsError = '[Refdata] GetMaterials Error',
  GetMaterialGroups = '[Refdata] GetMaterialGroups',
  GetMaterialGroupsSuccess = '[Refdata] GetMaterialGroups Success',
  GetMaterialGroupsError = '[Refdata] GetMaterialGroups Error',
  GetChannels = '[Refdata] GetChannels',
  GetChannelsSuccess = '[Refdata] GetChannels Success',
  GetChannelsError = '[Refdata] GetChannels Error',
  // ShowLoadingIndicator = '[Refdata] Show Loading',
  // HideLoadingIndicator = '[Refdata] Hide Loading',
}

export class StartRefdata implements Action {
  readonly type = RefdataActionTypes.StartRefdata;

  constructor(public payload: string) {
  }
}

export class ShowGlobalFeedback implements Action {
  readonly type = RefdataActionTypes.ShowGlobalFeedback;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class InitializeRefdata implements Action {
  readonly type = RefdataActionTypes.InitializeRefdata;

  constructor() {
  }
}

export class GetRefdata implements Action {
  readonly type = RefdataActionTypes.GetRefdata;

  constructor(public payload: RefdataType) {
  }
}

export class GetRefdataSuccess implements Action {
  readonly type = RefdataActionTypes.GetRefdataSuccess;

  constructor(public payload: IRefdataGetResponseModel) {
  }
}

export class GetRefdataError implements Action {
  readonly type = RefdataActionTypes.GetRefdataError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetCountries implements Action {
  readonly type = RefdataActionTypes.GetCountries;

  constructor() {
  }
}

export class GetCountriesSuccess implements Action {
  readonly type = RefdataActionTypes.GetCountriesSuccess;

  constructor(public payload: ICountryRefdataModel[]) {
  }
}

export class GetCountriesError implements Action {
  readonly type = RefdataActionTypes.GetCountriesError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetExchangeRates implements Action {
  readonly type = RefdataActionTypes.GetExchangeRates;

  constructor() {
  }
}

export class GetExchangeRatesSuccess implements Action {
  readonly type = RefdataActionTypes.GetExchangeRatesSuccess;

  constructor(public payload: IExchangeRateRefdataModel[]) {
  }
}

export class GetExchangeRatesError implements Action {
  readonly type = RefdataActionTypes.GetExchangeRatesError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetMaterials implements Action {
  readonly type = RefdataActionTypes.GetMaterials;

  constructor() {
  }
}

export class GetMaterialsSuccess implements Action {
  readonly type = RefdataActionTypes.GetMaterialsSuccess;

  constructor(public payload: IMaterialRefdataModel[]) {
  }
}

export class GetMaterialsError implements Action {
  readonly type = RefdataActionTypes.GetMaterialsError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetMaterialGroups implements Action {
  readonly type = RefdataActionTypes.GetMaterialGroups;

  constructor() {
  }
}

export class GetMaterialGroupsSuccess implements Action {
  readonly type = RefdataActionTypes.GetMaterialGroupsSuccess;

  constructor(public payload: IMaterialGroupRefdataModel[]) {
  }
}

export class GetMaterialGroupsError implements Action {
  readonly type = RefdataActionTypes.GetMaterialGroupsError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetChannels implements Action {
  readonly type = RefdataActionTypes.GetChannels;

  constructor() {
  }
}

export class GetChannelsSuccess implements Action {
  readonly type = RefdataActionTypes.GetChannelsSuccess;

  constructor(public payload: IChannelRefdataModel[]) {
  }
}

export class GetChannelsError implements Action {
  readonly type = RefdataActionTypes.GetChannelsError;

  constructor(public payload: IFeedbackModel[]) {
  }
}

export type RefdataActions =
  StartRefdata |
  ShowGlobalFeedback |
  InitializeRefdata |
  GetRefdata |
  GetRefdataSuccess |
  GetRefdataError |
  GetCountries |
  GetCountriesSuccess |
  GetCountriesError |
  GetExchangeRates |
  GetExchangeRatesSuccess |
  GetExchangeRatesError |
  GetMaterials |
  GetMaterialsSuccess |
  GetMaterialsError |
  GetMaterialGroups |
  GetMaterialGroupsSuccess |
  GetMaterialGroupsError |
  GetChannels |
  GetChannelsSuccess |
  GetChannelsError;
