import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserState} from '../../user/state/user.reducer';
import {ISarModel} from '../../sar/models/i-sar.model';
import {getSars, getSelectedSarId} from '../../sar/state/sar.selectors';
import {IUserModel} from '../models/i-user.model';

const getUserFeatureState = createFeatureSelector<UserState>('user');

export const getLoggedUserName = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.name : null;
  }
);

export const getLoggedUserRoles = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.userRoles : null;
  }
);

export const getLoggedUserIsAdmin = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.userRoles.includes('Admin') : null;
  }
);

export const getSearchedUsers = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.searchedUsers : null;
  }
);

export const getUsers = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.users : null;
  }
);

export const getDashboardUsers = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.dashboardUsers : null;
  }
);

export const getRoles = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.roles : null;
  }
);

export const getRoleAttributes = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.roleAttributes : null;
  }
);

export const getCurrentUserId = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.currentUserId : null;
  }
);

export const getCurrentUser = createSelector(
  getUserFeatureState,
  state => {
    return state ? state.currentUser : null;
  }
);

export const getSelectedUser = createSelector(
  getCurrentUserId,
  getCurrentUser,
  (currentUserId, currentUser) => {
    if (currentUserId === '0') {
      const newObj: IUserModel = {
        id: '0',
        name: '',
        userName: '',
        cdsid : '',
        initials: '',
        email: '',
        phoneNumber: '',
        isActive: true,
        userRoles: [],
        departments: [],
        orgs: [],
        plants: [],
        storageLocations: [],
        avatarUrl : '',
        superUser: false,
        channels: [],
        notificationsFrequency: null
      };
      return newObj;
    } else {
      return (currentUser && currentUser.id === currentUserId) ? currentUser : null;
    }
  }
);
